@font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 900;
    src: url("./fonts/iransans/IRANSansWeb(NoEn)_Black.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/iransans/IRANSansWeb(NoEn)_Bold.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/iransans/IRANSansWeb(NoEn)_Medium.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/iransans/IRANSansWeb(NoEn)_Light.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 200;
    src: url("./fonts/iransans/IRANSansWeb(NoEn)_UltraLight.woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/iransans/IRANSansWeb(NoEn).woff") format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  }
  