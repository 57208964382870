@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./iransans.scss";
@import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
@import "react-toastify/dist/ReactToastify.css";

body {
  font-family: IRANSans, Arial, Helvetica, sans-serif;
}

.Toastify__toast-body {
  font-family: IRANSans, Arial, Helvetica, sans-serif !important;
}

.Calendar {
  margin: auto;
  width: 100% !important;
}
